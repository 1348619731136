import PropTypes from 'prop-types';
import React from 'react';
import { Link, NavLink as RouterNavLink } from 'react-router-dom';

import lfHoc from '@holmanfm/lib/lf-hoc/lf-hoc';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import { preLogOut } from '@holmanfm/lib/redux/actions/auth.actions';
import { Org } from '@holmanfm/lib/common/app-prop-types';
import { useNSTranslation, Trans } from '@holmanfm/lib/lang';
import Features from '@holmanfm/lib/lib-global';
import usePermissions from '@holmanfm/lib/hooks/use-permissions';
import HomeButton from '~/areas/nav/home-button';
import IconButton from '~/shared/components/atom/icon-button';
import Toolbar from '~/shared/components/bar/toolbar';
import Box from '~/shared/components/box';
import Drawer from '~/shared/components/drawer';
import List from '~/shared/components/list/list';

import NavLink from './nav-link';
import UserNavigationMenu from './user-navigation-menu';
import Menu from '~/shared/icons/menu';
import ListItemButton from '~/shared/components/list-item-button';
import ListItemText from '~/shared/components/list-item-text';
import makeStyles from '~/shared/components/makeStyles';
import RequestWithCount from '~/shared/icons/request-with-count';
import Typography from '~/shared/components/atom/typography/typography';

import { AceBanner } from '~/areas/ace';
import ContactUsQuestionMark from '~/shared/icons/contact-us-question-mark';
import ClosedSquare from '~/shared/icons/close-square';
import StyledDialog from '~/shared/components/styled-dialog';
import WarningWhite from '~/shared/icons/warning-white';
import useDialog from '@holmanfm/lib/hooks/use-dialog';

const ForwardNavLink = React.forwardRef((props, ref) => (
  <RouterNavLink {...props} ref={ref} />
));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  requestIconWrapper: {
    marginRight: theme.spacing(3),
  },
  requestsLabelWrapper: {
    whiteSpace: 'nowrap',
    marginRight: '0.5em',
  },
  toolbarButtons: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  text: {
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      color: 'inherit',
    },
  },
  navigationBar: {
    top: 0,
    left: 'auto',
    right: 0,
    position: 'sticky',
    width: '100%',
    display: 'flex',
    zIndex: 1100,
    boxSizing: 'border-box',
    flexShrink: 0,
    flexDirection: 'column',
  },
}));

export const Nav = (props) => {
  const { routeData, org } = props;
  const { canAccessFeature, canAccessFullMenu } = usePermissions();
  const canViewRequests = canAccessFeature(Features.CUSTOMERS.REQUESTS);

  const { t } = useNSTranslation('main', 'nav.primary');
  const { t: tNav } = useNSTranslation('nav', 'primary');

  const classes = useStyles();

  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  const logout = () => props.preLogOut(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const orgLabel = org?.data?.organizationName;

  const requestsNotification = org && (
    <>
      <Typography className={classes.requestsLabelWrapper}>
        {t('customers-service-request')}
      </Typography>

      <div className={classes.requestIconWrapper}>
        <RequestWithCount count={org.openRequestsCount} />
      </div>
    </>
  );

  const flexContainer = {
    height: '64px',
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  };

  const onClickLogout = () => {
    toggleSidebar();
    logout();
  };

  const {
    open: isRoadAssistOpen,
    openDialog: openRoadAssist,
    closeDialog: closeRoadAssist,
  } = useDialog();

  const hasRoadAssist = canAccessFeature(Features.ROADSIDE_ASSIST.INDEX);
  return (
    <div className={classes.navigationBar}>
      <AceBanner />
      <Toolbar className={classes.toolbar}>
        <Box display="flex">
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <IconButton
              className="browser-default"
              onClick={toggleSidebar}
              disabled={!canAccessFullMenu}
              style={{ color: '#fff' }}
            >
              <Menu />
            </IconButton>
          </Box>

          <HomeButton enabled={canAccessFullMenu} />

          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <List style={flexContainer} className={classes.text}>
              {routeData.map((curVal) => (
                <NavLink
                  className={classes.text}
                  route={curVal}
                  id={`${curVal.id}link`}
                  key={curVal.id}
                  topLevel
                />
              ))}

              {canViewRequests && (
                <ListItemButton
                  data-testid="top-level-menu-button-open-requests"
                  component={ForwardNavLink}
                  to="/customer/request-management"
                >
                  {requestsNotification}
                </ListItemButton>
              )}
            </List>
          </Box>

          <Drawer
            anchor="left"
            open={sidebarOpen}
            onClose={toggleSidebar}
            PaperProps={{ classes }}
          >
            <List style={{ width: 240 }} className={classes.text}>
              {routeData.map((curVal) => (
                <NavLink
                  className={classes.text}
                  route={curVal}
                  id={`${curVal.id}link`}
                  key={curVal.id}
                  topLevel
                  parentHandleClose={toggleSidebar}
                />
              ))}
              {canViewRequests && (
                <ListItemButton
                  data-testid="top-level-menu-button-open-requests"
                  component={ForwardNavLink}
                  to="/customer/request-management"
                  onClick={toggleSidebar}
                >
                  {requestsNotification}
                </ListItemButton>
              )}
            </List>
            <List style={{ width: 240 }} className={classes.text}>
              <ListItemButton onClick={openRoadAssist}>
                <ListItemText primary="Roadside Assistance" />
              </ListItemButton>
              <ListItemButton
                component={ForwardNavLink}
                to="/my-account"
                onClick={toggleSidebar}
              >
                <ListItemText primary="My Account" />
              </ListItemButton>
              <ListItemButton
                component={ForwardNavLink}
                to="/contact-us"
                onClick={toggleSidebar}
              >
                <ListItemText primary="Contact Us" />
              </ListItemButton>
              <ListItemButton onClick={onClickLogout}>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </List>
          </Drawer>
        </Box>

        <div className={classes.toolbarButtons}>
          <Typography sx={{ mr: 1 }} variant="body2">
            {orgLabel}
          </Typography>
          {}
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {hasRoadAssist && (
              <IconButton onClick={openRoadAssist}>
                <WarningWhite />
              </IconButton>
            )}
            <IconButton
              className="browser-default"
              component={Link}
              to="/contact-us"
            >
              <ContactUsQuestionMark />
            </IconButton>
            <UserNavigationMenu />
          </Box>
        </div>
      </Toolbar>
      <StyledDialog
        isOpen={isRoadAssistOpen}
        onClose={() => closeRoadAssist()}
        dialogTitle={tNav('road-assist-title')}
      >
        <Typography variant="body2" style={{ marginBottom: 16 }}>
          <Trans i18nKey="nav:primary.road-assist-description" />
        </Typography>
      </StyledDialog>
    </div>
  );
};

const NavigationRoute = PropTypes.shape({
  id: PropTypes.string.isRequired,
  filter: PropTypes.func,
  navigation: PropTypes.shape({
    to: PropTypes.string.isRequired,
  }).isRequired,
});

Nav.propTypes = {
  preLogOut: PropTypes.func.isRequired,
  routeData: PropTypes.arrayOf(NavigationRoute),
  org: Org,
};

Nav.defaultProps = {
  routeData: [],
  org: null,
};

const mapStateToProps = (state) => ({
  org: selectors.getOrg(state),
  isFetchingOrg: selectors.getIsFetchingOrg(state),
});

const lfContainer = lfHoc(mapStateToProps, { preLogOut });

export default lfContainer(Nav);
